<template>
  <main>
    <section class="contenedor" id="bienvenida">
      <div>
        <h2><p>Con mas de 50 años de experiencia en Mar del Plata, somos la opción preferida por quienes valoran la seguridad y la calidad. Ya sea para estadias cortas, largas o abonos mensuales, contamos con tarifas competitivas y un ambiente de confianza para su vehículo.</p></h2>
      </div>
    </section>

    <div class="rectangulo"></div>

    <section id="servicios">
      <h2>Servicios</h2>
      <div class="imagenes-textos">
        <div class="elemento">
          <img src="../imagenes/bike.jpg" alt="Motocicletas" />
          <p>Sector exclusivo para motocicletas.</p>
        </div>
        <div class="elemento">
          <img src="../imagenes/camarasseguridad.jpg" alt="Vigilancia 24hrs" />
          <p>Vigilancia 24hrs</p>
        </div>
        <div class="elemento">
          <img src="../imagenes/valetparking.avif" alt="Valet Parking" />
          <p>Valet Parking</p>
        </div>
        <div class="elemento">
          <img src="../imagenes/car.avif" alt="Espacios amplios" />
          <p>Espacios amplios para autos, camionetas, traffics.</p>
        </div>
        <div class="elemento">
          <img src="../imagenes/24horas.jpg" alt="24 horas" />
          <p>Abierto las 24hrs todo el año.</p>
        </div>
        <div class="elemento">
          <img src="../imagenes/driver.webp" alt="Auto estacionamiento" />
          <p>Puedes estacionarlo y llevarte la llave.</p>
        </div>
      </div>
    </section>

    <div class="rectangulo"></div>

    <section id="ubicacion">
      <h2>Ubicación</h2>
      <p>Encuentra nuestra ubicación:</p>
      <div id="map">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3144.00783272744!2d-57.55138052445005!3d-38.00027777192934!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9584dc1cba5aa233%3A0x8c57c3a3c261016d!2sBelgrano%202641%2C%20B7600GKM%20Mar%20del%20Plata%2C%20Provincia%20de%20Buenos%20Aires!5e0!3m2!1sen!2sar!4v1732659100757!5m2!1sen!2sar"
          width="100%" height="100%" style="border:0;" allowfullscreen="" loading="lazy"
          referrerpolicy="no-referrer-when-downgrade">
        </iframe>
      </div>
    </section>

    <div class="rectangulo"></div>

    <section class="clientes">
      <h2>Nuestros clientes</h2>
      <div class="carrusel">
        <div class="logo" v-for="(logo, index) in logos" :key="index">
          <img :src="logo.src" :alt="'Logo ' + (index + 1)">
        </div>
      </div>
    </section>
  </main>
</template>

<script>
export default {
  name: 'AppMain',
  data() {
    return {
      logos: [
        { src: require('../imagenes/larozse.webp') },
        { src: require('../imagenes/boleir.webp') },
        { src: require('../imagenes/mapmaster.webp') },
        { src: require('../imagenes/darkside.webp') },
        { src: require('../imagenes/digit.webp') },
        { src: require('../imagenes/col.webp') }
      ]
    };
  }
};
</script>

<style scoped>
/* Estilos generales */
main {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.contenedor {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: 'Arial', sans-serif;
  margin-bottom: 30px;
}

section {
  width: 100%;
  max-width: 800px;
  margin-bottom: 30px;
  padding: 10px;
  padding-top: 70px;
  margin-top: -70px;
}

h2 {
  color: #052105;
  font-weight: bold;
  margin-top: 20px;
  text-align: center;
  font-size: 2rem; 
}

p {
  font-size: 1rem;
  line-height: 1.5;
  text-align: center;
  max-width: 579px;
  text-align: justify;
}

/* Estilos para el mapa */
#map {
  height: 400px;
  width: 100%;
}

.clientes {
  text-align: center;
  padding: 20px;
  padding-top: 70px;
  margin-top: -70px;
}

.carrusel {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.logo {
  flex: 0 0 auto;
  margin-right: 30px;
}

.logo img {
  width: 120px;
  height: auto;
  object-fit: contain;
}

.rectangulo {
  width: 100%;
  height: 1px;
  background-color: #052105;
}

#servicios {
  text-align: center;
}

.imagenes-textos {
  display: grid;
  gap: 30px;
  grid-template-columns: repeat(3, 1fr);
  justify-content: center;
}

.elemento {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.elemento img {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 50%;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  margin-bottom: 15px;
}

.elemento p {
  font-size: 1rem;
  text-align: center;
  font-weight: 500;
  max-width: 180px;
  margin: 0;
  color: #333;
}


/* Responsividad */
@media (max-width: 768px) {
  h2 {
    font-size: 1.8rem;
  }

  p {
    font-size: 0.9rem;
  }

  .imagenes-textos {
    grid-template-columns: repeat(2, 1fr);
  }

  .logo img {
    width: 100px;
  }
}

@media (max-width: 480px) {
  h2 {
    font-size: 1.5rem;
  }

  p {
    font-size: 0.8rem;
  }

  .imagenes-textos {
    grid-template-columns: 1fr;
  }

  .logo img {
    width: 80px;
  }
}
</style>