<template>
  <div id="app">
    <AppHeader />
    <AppMain />
    <WhatsappWidget />
    <AppFooter />
  </div>
</template>

<script>
import AppHeader from './components/Header.vue';
import AppMain from './components/Main.vue';
import WhatsappWidget from '@/components/WhatsappWidget.vue';
import AppFooter from './components/Footer.vue';

export default {
  name: 'App',
  components: {
    AppHeader,
    AppMain,
    WhatsappWidget,
    AppFooter,
  },
  mounted() {
    // Bloqueamos el zoom sin modificar ninguna otra funcionalidad
    window.addEventListener('wheel', this.preventZoom, { passive: false });
    window.addEventListener('gesturestart', this.preventZoom, { passive: false });
    window.addEventListener('keydown', this.preventKeyZoom, { passive: false });
    
    // Bloqueamos el clic derecho (menú contextual)
    window.addEventListener('contextmenu', this.preventRightClick);
  },
  methods: {
    preventZoom(event) {
      // Bloquea el zoom pero permite el scroll
      if (event.ctrlKey || event.metaKey || event.deltaY === 0) {
        event.preventDefault(); // Solo previene el zoom
      }
    },
    preventKeyZoom(event) {
      // Bloquea el zoom con teclas Ctrl + Mouse Wheel
      if (event.ctrlKey && (event.key === '+' || event.key === '-' || event.key === '0')) {
        event.preventDefault();
      }
    },
    preventRightClick(event) {
      // Bloquea el clic derecho (menú contextual)
      event.preventDefault(); // Prevenir el menú contextual
    }
  },
  beforeUnmount() {
    // Limpiamos los event listeners cuando el componente se destruya
    window.removeEventListener('wheel', this.preventZoom);
    window.removeEventListener('gesturestart', this.preventZoom);
    window.removeEventListener('keydown', this.preventKeyZoom);
    window.removeEventListener('contextmenu', this.preventRightClick); // Eliminar bloqueo del clic derecho
  }
};
</script>

<style>
/* Asegura que el body ocupe toda la pantalla */
html, body {
  margin: 0;
  padding: 0;
  height: 100%; /* Garantiza que la altura sea del 100% */
}

#app {
  display: flex;
  flex-direction: column;
}

main {
  flex: 0; /* Ocupa todo el espacio disponible */
}

/* Footer se queda abajo sin importar el zoom */
footer {
  width: 100%;
  margin: 0;
  padding: 0;
}
</style>
