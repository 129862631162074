<template>
  <footer id="contacto" class="footer">
    <div class="footer-container">
      <div class="footer-section">
        <h3><b>Acerca de Nosotros</b></h3>
        <p>En Cochera Belgrano, con más de 50 años de experiencia, ofrecemos estacionamiento seguro y de confianza en el corazón de la ciudad.</p>
      </div>


      <div class="footer-section">
        <h3><b>Contacto</b></h3>
        <p> +549 (223) 517-1339</p>
        <p> info@cocherabelgrano.com.ar</p>
      </div>
      <div class="footer-section">
        <h3><b>Ubicación</b></h3>
        <p>Belgrano 2641, Pb, Mar Del Plata Norte (7600)</p>
      </div>

      <div class="footer-section">
        <h3><b>Horario de atención</b></h3>
        <p><b>Personal:</b></p>
        <p>Cochera abierto las 24 Hs., todo el año.</p>
        <p><b>Telefónica:</b></p>
        <p>Lunes a viernes, de 9 a 17 Hs.</p>
        <p>Sábados, de 9 a 13 Hs. </p>
      </div>
    </div>
    <p>&copy; 1967-2024 CELEMAR S.R.L</p>
  </footer>
</template>

<script>
export default {
  name: 'AppFooter',
};
</script>

<style scoped>
/* Estilo general del footer */
.footer {
  background-color: #052105;
  color: white;
  padding: 20px 0px;
  text-align: center;
  position: relative;
  width: 100%; /* Asegura que ocupe todo el ancho de la pantalla */
  margin: 0; /* Elimina márgenes externos */
}

.footer-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 20px;
  max-width: 1200px; /* Tamaño máximo */
  margin: 0 auto;
  text-align: left; /* Alinea el contenido a la izquierda */
}

.footer-section {
  flex: 1;
  min-width: 200px; /* Para mantener bloques manejables */
  padding: 10px ;
}

.footer-section h3 {
  font-size: 1.2rem;
  margin: 0 0 0px;
  text-align: left;
}

.footer-section p,
.footer-section ul {
  font-size: 0.9rem;
  margin: 0;
}

.footer-section a {
  color: #fff;
  text-decoration: none;
}

.footer-section a:hover {
  text-decoration: underline;
}

/* Estilo para pantallas más pequeñas */
@media (max-width: 768px) {
  .footer-container {
    flex-direction: column; /* Pone los bloques uno debajo de otro */
    gap: 10px;
    margin: 0 auto;
  }

  .footer-section {
    text-align: left; /* Alinea los bloques al centro en móviles */
  }

  .footer-section h3 {
    text-align: left; /* Títulos centrados en móviles */
  }

  .footer-section ul {
    padding: 0;
    list-style: none; /* Elimina los puntos de la lista en móviles */
  }
}

/* Estilo para pantallas ultra pequeñas (teléfonos) */
@media (max-width: 480px) {
  .footer {
    padding: 0px ; /* Reduce el espacio para ajustarse mejor */
  }

  .footer-section h3 {
    font-size: 1rem ; /* Reduce el tamaño del título */
  }

  .footer-section p,
  .footer-section ul {
    font-size: 0.8rem; /* Texto más pequeño */
  }
}

@media (max-width: 890px) {
  .footer {
    padding: 0px ; /* Reduce el espacio para ajustarse mejor */
  }

  .footer-section h3 {
    font-size: 1rem ; /* Reduce el tamaño del título */
  }

  .footer-section p,
  .footer-section ul {
    font-size: 0.8rem; /* Texto más pequeño */
  }
}
</style>